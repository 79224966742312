import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import RawHTML from '@components/Common/RawHTML/index';
import SEO from '@components/Common/SEO/index';
import CompanyEventNavbar from '@components/CompanyEventPages/CompanyEventNavbar/index';
import { InView, useInView } from 'react-intersection-observer';
import { navigate } from '@reach/router';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import * as styles from '../yaml-pages-styles/geekTrust.module.scss';
import CompanyEventScheduleTabs from '@components/CompanyEventPages/CompanyEventScheduleTabs/index';
import CompanyEventCTA from '@components/CompanyEventPages/CompanyEventCTA/index';
import ScrollCTA from '@components/ScrollCTA/index';
import CompanyEventCTAButton from '@components/CompanyEventPages/CompanyEventCTAButton/index';
import { Helmet } from 'react-helmet';
import twConfig from '../../utils/tailwind-fullConfig';
import useResizer from '@components/extra/useResizer';
import FspCarousel from '@components/v5/FspCurriculumSection/FspCarousel/index';

export default function GeekTrust({ data: queryData }) {
  const { geekTrustPageYaml: data } = queryData;
  const isMobile = useResizer();
  const partnerLogo = getImage(data.partnerLogo.childImageSharp);
  const heroImage = getImage(data.heroImage.childImageSharp);
  // console.log('debug > ', data);

  // const preFooterDesktop = getImage(
  //   data.preFooterImage.desktop.childImageSharp,
  // );

  const [navbarIndex, setNavbarIndex] = useState(0);
  const [scheduleIndex, setScheduleIndex] = useState(0);

  const location = useLocation();

  // redirect geek trust page until its in use again
  // useEffect(() => {
  //   if (window) {
  //     window.location.replace(
  //       '/software-development-fellowship-program/' + location?.search,
  //     );
  //   }
  // }, []);

  const handleScheduleChange = (index) => {
    setScheduleIndex(index);
  };

  // return (
  //   <div className="flex h-screen w-screen items-center justify-center">
  //     <Loader></Loader>
  //   </div>
  // );

  return (
    <div className={data.bodyClass}>
      <Helmet
        meta={[
          {
            name: `theme-color`,
            content: twConfig.theme.colors['geek-trust']['green']['300'],
          },
        ]}
      ></Helmet>
      <SEO title={data.SEOTitle} description={data.SEODescription} />{' '}
      <ScrollCTA
        startAt={500}
        link={data.typeformSlug}
        type={data.type}
        secondaryType={data.secondaryType}
        location="FLOATING_CTA"
        openEvent="FREE_ME_START_NOW_CLICKED"
        closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
      >
        <CompanyEventCTAButton
          secondarytype={data.secondaryType}
          className=" w-full"
        />
      </ScrollCTA>
      <CompanyEventNavbar
        className="bg-geek-trust-purple-300 "
        navbarIndex={navbarIndex}
        data={data.navigation}
        logo={data.logo.childImageSharp}
        route={'/geektrust-tech-scholars-program'}
        variant="GEEK_TRUST"
      />
      <InView
        threshold={0.5}
        onChange={(isVisible) => {
          if (isVisible) {
            setNavbarIndex(0);
          }
        }}
      >
        <section
          className={`${styles.hero} ${styles.block} pt-16 lg:pt-16 lg:pb-5`}
        >
          <div className="container  flex flex-wrap justify-center lg:flex-nowrap lg:justify-between">
            <div className="flex flex-col items-center justify-center  text-center md:w-1/2 lg:block lg:text-left">
              <div className="mb-5">
                <div className="w-[150px] md:w-[300px]">
                  <GatsbyImage
                    className="max-w-[150px]"
                    loading="eager"
                    image={partnerLogo}
                    alt={'GeekTrust X Crio'}
                  />
                </div>
              </div>
              <div className="reveal">
                <h1 className={`reveal__content ${styles.heroTitle} mb-1`}>
                  <RawHTML>{data.heroTitle}</RawHTML>
                </h1>
              </div>
              <div className="reveal mb-5">
                <h3
                  className={`reveal__content ${styles.caption} mb-1 text-xl  font-normal text-geek-trust-neutral-200`}
                >
                  Powered by Geektrust
                </h3>{' '}
              </div>
              <div className="reveal mb-6">
                <RawHTML className="reveal__content lg:text-md font-lato  text-base font-normal leading-loose text-geek-trust-neutral-200">
                  {data.heroDescription}
                </RawHTML>
              </div>
              <div className="overflow-hidden">
                <motion.div
                  variants={{
                    initial: {
                      y: 100,
                    },

                    animate: {
                      y: 0,
                      transition: {
                        ease: 'easeOut',
                        duration: 0.5,
                        delay: isMobile ? 1 : 1.5,
                        type: 'tween',
                      },
                    },
                  }}
                  initial="initial"
                  animate="animate"
                >
                  <CompanyEventCTA
                    className="mb-10"
                    link={data.typeformSlug}
                    type={data.type}
                    secondaryType={data.secondaryType}
                    location="HERO_SECTION"
                  />
                </motion.div>
              </div>
            </div>
            <div className="lg:min-w-[600px]">
              <GatsbyImage
                loading="eager"
                image={heroImage}
                className="w-full"
                alt={'Hero'}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          </div>
        </section>
      </InView>
      <section
        className={`${styles.block} ${styles.highlights}  relative z-10 pb-12 text-center`}
      >
        <FadeInWhenVisible
          className="w-full"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 },
          }}
        >
          <div className="container">
            <div className="mb-16 ">
              <h2 className={`${styles.blockTitle}`}>
                <RawHTML>{data.highlightsTitle}</RawHTML>
              </h2>
              <div className="flex justify-center">
                <div className="w-fit rounded-full bg-geek-trust-green-300 py-2 px-4 text-center">
                  Limited Seats Available!
                </div>
              </div>
            </div>
            <div className="mb-4 flex flex-wrap items-start justify-start gap-x-4 gap-y-8 lg:mb-10 lg:flex-nowrap">
              {data.highlights.map((ele, idx) => {
                const images = getImage(ele.image.childImageSharp);
                return (
                  <div
                    className="flex items-center justify-center gap-x-8 gap-y-4 text-left lg:w-1/3 lg:flex-col lg:text-center"
                    key={idx}
                  >
                    <div className="w-[50px] md:w-[140px] lg:w-auto">
                      <GatsbyImage
                        loading="lazy"
                        image={images}
                        alt={'Highlight'}
                        className="w-[50px] md:w-full"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </div>
                    <p className="text-md font-nunito-sans font-semibold leading-loose text-geek-trust-purple-300">
                      <RawHTML>{ele.content}</RawHTML>
                    </p>{' '}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center">
              <CompanyEventCTA
                className="mb-10"
                link={data.typeformSlug}
                type={data.type}
                secondaryType={data.secondaryType}
                location="HIGHLIGHTS_SECTION"
              />
            </div>
            <h3 className="font-dosis font-black text-geek-trust-green-300">
              <RawHTML>{data.highlightsSummary}</RawHTML>
            </h3>
          </div>
        </FadeInWhenVisible>
      </section>
      <section
        className={`${styles.block} ${styles.highlights}  relative z-10 pt-4 pb-48 text-center md:pb-40`}
      >
        <FadeInWhenVisible
          className="w-full"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 },
          }}
        >
          <div className="container">
            <div className="mb-16">
              <h2 className={`${styles.blockTitle} text-geek-trust-purple-200`}>
                <RawHTML>{data.companyTitle}</RawHTML>
              </h2>
            </div>
            <div className="mb-12">
              <FspCarousel
                data={data.companiesData}
                variant="GEEK_TRUST"
              ></FspCarousel>
            </div>
            <div className="flex justify-center">
              <CompanyEventCTA
                className="mb-10"
                link={data.typeformSlug}
                type={data.type}
                secondaryType={data.secondaryType}
                location="HIGHLIGHTS_SECTION"
              />
            </div>
          </div>
        </FadeInWhenVisible>
      </section>
      <InView
        threshold={0.5}
        onChange={(isVisible) => {
          if (isVisible) {
            setNavbarIndex(1);
          }
        }}
      >
        <section
          id="how-to-join-geek-trust-program"
          className={`relative ${styles.block} ${styles.joinDetails} pt-52 text-center sm:pt-48 md:pt-40`}
        >
          <div className={`${styles.floatingBar} px-[15px]`}>
            <div className={`${styles.floatingBarContent} relative`}>
              <div className="absolute -top-24 -right-16 z-0 opacity-60 lg:-top-10 lg:-right-4">
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Box Elements/Dark-Purple-Dots.png"
                  placeholder="none"
                  width={135}
                  alt="Dot Elements"
                  className=""
                />
              </div>
              <motion.div
                animate={{ scale: 0.7 }}
                className=" absolute -left-60 z-0 lg:-left-40"
                transition={{
                  repeat: Infinity,
                  duration: 3,
                  repeatType: 'reverse',
                  type: 'tween',
                }}
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Box Elements/Dark-Purple-Circle.png"
                  placeholder="none"
                  width={300}
                  alt="Dot Elements"
                  className=""
                />
              </motion.div>
              <motion.div
                animate={{ scale: 0.7, delay: 2 }}
                className="absolute -top-20 left-24 z-0  lg:-top-16"
                transition={{
                  repeat: Infinity,
                  duration: 3,
                  repeatType: 'reverse',
                  type: 'tween',
                  delay: 2,
                  // delayChildren: 2,
                  repeatDelay: 1,
                }}
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Box Elements/Dark-Purple-Circle.png"
                  placeholder="none"
                  width={100}
                  alt="Dot Elements"
                  className=""
                />
              </motion.div>
              <motion.div
                animate={{ y: 30, delay: 2 }}
                className="absolute -bottom-12 right-10  z-0"
                transition={{
                  repeat: Infinity,
                  duration: 2,
                  repeatType: 'reverse',
                  type: 'tween',
                  delay: 2,
                  // delayChildren: 2,
                  repeatDelay: 0.5,
                }}
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Box Elements/Dark-Purple-Circle.png"
                  placeholder="none"
                  width={100}
                  alt="Dot Elements"
                  className="  "
                />
              </motion.div>
              <RawHTML className={`z-10 text-3xl  ${styles.floatingBarText} `}>
                {data.floater}
              </RawHTML>
            </div>
          </div>

          <FadeInWhenVisible
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
          >
            <div className="container">
              <div className="mb-16">
                <h2 className={`${styles.blockTitle}`}>
                  <RawHTML>{data.joinDetailsTitle}</RawHTML>
                </h2>
              </div>
              <div className="mb-10 flex flex-col items-center justify-center gap-x-20 gap-y-10 lg:flex-row lg:flex-nowrap">
                {data.joinDetails.map((ele, idx) => {
                  const images = getImage(ele.image.childImageSharp);
                  return (
                    <div
                      key={idx}
                      className="flex flex-col items-center justify-center gap-x-3 gap-y-4"
                    >
                      <div>
                        <GatsbyImage
                          loading="lazy"
                          image={images}
                          className="w-[150px] lg:w-auto "
                          alt={'Joining Detail'}
                          imgStyle={{ objectFit: 'contain' }}
                        />
                      </div>
                      <RawHTML className="lg:text-md px-8 text-lg font-normal leading-loose text-geek-trust-purple-200">
                        {ele.content}
                      </RawHTML>
                    </div>
                  );
                })}
              </div>
              {/* <div className="flex w-full justify-center">
                <div className={`${styles.divider} mb-10`}></div>
              </div> */}
            </div>
            <div className="mb-10 flex justify-center">
              <CompanyEventCTA
                link={data.typeformSlug}
                type={data.type}
                secondaryType={data.secondaryType}
                location="JOIN_SECTION"
              />
            </div>{' '}
            <p className={` ${styles.subCaption} mx-4 md:mx-auto`}>
              Candidates will be chosen based on the{' '}
              <span className="font-bold text-geek-trust-purple-300">
                quality of their application
              </span>{' '}
              <br className="hidden lg:block" />
              and their{' '}
              <span className="font-bold text-geek-trust-purple-300">
                performance in the programming assessment.
              </span>
            </p>
          </FadeInWhenVisible>
        </section>
      </InView>
      <InView
        threshold={0.5}
        onChange={(isVisible) => {
          if (isVisible) {
            setNavbarIndex(2);
          }
        }}
      >
        <section
          className={`${styles.block} ${styles.schedule} pb-48 text-center md:pb-40 `}
          id="geek-trust-program-schedule"
        >
          <FadeInWhenVisible
            className="w-full"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
          >
            <div className="container">
              <div className="mb-16">
                <h2 className={`${styles.blockTitle}`}>
                  <RawHTML>{data.scheduleTitle}</RawHTML>
                </h2>
              </div>

              <div className="lg:px-20">
                <div className=" mb-10 flex w-full justify-center">
                  <CompanyEventScheduleTabs
                    index={scheduleIndex}
                    onChange={handleScheduleChange}
                    data={data.schedule}
                    variant={data.secondaryType}
                  ></CompanyEventScheduleTabs>
                </div>
                <div
                  className={`${styles.scheduleContent} overflow-hidden p-5 lg:p-10`}
                >
                  <AnimatePresence exitBeforeEnter>
                    <motion.div
                      key={data.schedule[scheduleIndex].label}
                      variants={{
                        initial: {
                          y: -500,
                          opacity: 0,
                        },

                        animate: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                          transition: {
                            ease: 'easeOut',
                            duration: 0.5,
                            type: 'tween',
                          },
                        },

                        exit: {
                          x: 0,
                          y: 500,
                          opacity: 0,
                          transition: {
                            duration: 0.2,
                          },
                        },
                      }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <div className="flex flex-col justify-between">
                        <div>
                          <h2
                            className={`${styles.scheduleContentTitle} mb-5 font-semibold`}
                          >
                            {data.schedule[scheduleIndex].title}
                          </h2>
                          <p className="mb-10 font-normal leading-loose lg:px-20">
                            {data.schedule[scheduleIndex].description}
                          </p>
                        </div>
                        <div className="flex justify-center">
                          <div className="flex justify-between gap-x-20">
                            <div>
                              <h6
                                className={`${styles.scheduleContentCaption}`}
                              >
                                Duration
                              </h6>
                              <h4 className="font-black text-geek-trust-purple-300">
                                {' '}
                                {data.schedule[scheduleIndex].duration}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </FadeInWhenVisible>
        </section>
      </InView>
      <section className={`${styles.block} ${styles.preFooter} relative  pb-0`}>
        <FadeInWhenVisible
          className="w-full"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 },
          }}
        >
          <div className="container overflow-hidden">
            <div className="flex flex-wrap items-center justify-center text-center lg:flex-nowrap lg:justify-between lg:text-left">
              <div>
                <h1
                  className={`${styles.blockTitle} mb-6 font-normal leading-tight text-geek-trust-neutral-100 lg:text-5xl lg:leading-snug`}
                >
                  <RawHTML>{data.preFooterTitle}</RawHTML>
                </h1>
                <RawHTML className="text-md mb-10 font-semibold leading-relaxed text-geek-trust-neutral-300">
                  {data.preFooterDescription}
                </RawHTML>
                <div className="flex justify-center lg:justify-start">
                  <CompanyEventCTA
                    className="mb-10"
                    link={data.typeformSlug}
                    type={data.type}
                    secondaryType={data.secondaryType}
                    location="PRE_FOOTER_SECTION"
                  />
                </div>
              </div>
              <div className="lg:w-1/2">
                {/* <GatsbyImage
                  loading="lazy"
                  image={preFooterDesktop}
                  alt={'Be part of the journey to transform payments in India'}
                  imgStyle={{ objectFit: 'contain' }}
                /> */}
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Footer-Image.png"
                  placeholder="none"
                  width={600}
                  alt="Dot Elements"
                  className=""
                />
              </div>
            </div>
          </div>
        </FadeInWhenVisible>
      </section>
      <section className={`${styles.block} ${styles.footer} py-10 text-center`}>
        <div className="container flex flex-col items-center justify-center gap-y-6">
          <div>
            <div className="w-[150px]">
              <GatsbyImage
                loading="eager"
                image={partnerLogo}
                alt={'GeekTrust X Crio'}
                // imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <p className="mt-4 mb-2 text-lg text-geek-trust-neutral-300">
              Powered by
            </p>
            <div className="w-[150px]">
              <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../images/geektrust/GeekTrust-Logo.png"
                placeholder="blurred"
                width={300}
                alt="Geek Trust"
              />
            </div>
          </div>
          <div
            className="cursor-pointer p-3 transition-all hover:opacity-50"
            onClick={() => {
              navigate(data.slug);
            }}
          >
            <FontAwesomeIcon
              className={styles.arrow}
              size="2x"
              icon={['far', 'chevron-circle-up']}
            ></FontAwesomeIcon>
          </div>
          <div>
            <div className="mb-4 flex flex-wrap items-center justify-center gap-x-6 gap-y-6">
              {' '}
              <a
                href={'https://www.linkedin.com/company/criodo/'}
                target="_blank"
                className="transition-all hover:opacity-50"
                rel="noreferrer"
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Social/linkedin.png"
                  placeholder="none"
                  width={50}
                  alt="Dot Elements"
                  className="w-[35px]"
                />
              </a>
              <a
                href={'https://www.facebook.com/criodo'}
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-50"
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Social/facebook.png"
                  placeholder="none"
                  width={50}
                  alt="Dot Elements"
                  className="w-[35px]"
                />
              </a>
              <a
                href={'https://www.instagram.com/crio.do/'}
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-50"
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Social/instagram.png"
                  placeholder="none"
                  width={50}
                  alt="Dot Elements"
                  className="w-[35px]"
                />
              </a>
              <a
                href={'https://twitter.com/crio_do/'}
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-50"
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Social/twitter.png"
                  placeholder="none"
                  width={50}
                  alt="Dot Elements"
                  className="w-[35px]"
                />
              </a>
              <a
                href={
                  'https://www.youtube.com/channel/UCE1_bZxswCCARhhVeSQLuew/'
                }
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-50"
              >
                <StaticImage
                  loading="lazy"
                  src="../images/geektrust/Social/youtube.png"
                  placeholder="none"
                  width={50}
                  alt="Dot Elements"
                  className="w-[35px]"
                />
              </a>
            </div>
            <div
              className={`flex w-full flex-wrap justify-center gap-x-6 gap-y-6`}
            >
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener"
                className="hover:underline"
              >
                <p>Privacy Policy</p>{' '}
              </a>
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                <p>Terms of Use</p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      //   exit="hidden"
    >
      {props.children}
    </motion.div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    geekTrustPageYaml(id: { eq: $id }) {
      SEODescription
      SEOTitle
      bodyClass
      floater
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 600
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
      heroTitle
      highlights {
        image {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 170
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        content
      }

      companyTitle
      companiesData {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      highlightsSummary
      highlightsTitle
      joinDetails {
        content
        image {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 205
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      joinDetailsSummary
      joinDetailsTitle
      partnerLogo {
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 300
            layout: FULL_WIDTH
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
      logo {
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 500
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
      navigation {
        content
        anchor
      }
      preFooterDescription

      preFooterTitle
      schedule {
        label
        startDate
        duration
        description
        title
      }
      scheduleFloater {
        description
        title
      }
      scheduleTitle
      slug
      typeformSlug

      type
      secondaryType
    }
  }
`;
// image {
//         childImageSharp {
//           gatsbyImageData(
//             blurredOptions: {}
//             width: 50
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//           )
//         }
//       }
