import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import pMinDelay from 'p-min-delay';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

export default function FspCarousel(props) {
  const CarouselV2CodeSplit = loadable(
    () =>
      pMinDelay(
        import('./CarouselV2CodeSplit/index'),
        300 + (props.delay || 0),
      ),
    {
      fallback: (
        <div className="mx-8 sm:mx-20">
          <div className="mb-4">
            <LoadingSkeleton></LoadingSkeleton>
          </div>
          <div className="mb-4">
            <LoadingSkeleton></LoadingSkeleton>
          </div>
          <div className="sm:hidden">
            <LoadingSkeleton></LoadingSkeleton>
          </div>
        </div>
      ),
    },
  );

  return (
    <FadeInWhenVisible
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      <CarouselV2CodeSplit {...props}></CarouselV2CodeSplit>
    </FadeInWhenVisible>
  );
}

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [hasComeInViewOnce, setHasComeInViewOnce] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setHasComeInViewOnce(true);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      //   exit="hidden"
    >
      {hasComeInViewOnce ? props.children : <Placeholder></Placeholder>}
    </motion.div>
  );
}

function Placeholder() {
  return <div className="h-[175px]"></div>;
}

function LoadingSkeleton() {
  const classes = useStyles();

  return (
    <div className=" flex h-full w-full gap-x-5 overflow-hidden ">
      {Array(10)
        .fill()
        .map((ele, idx) => (
          <div key={idx} className={`${classes.avatarSkeletonContainer} `}>
            <Skeleton className={classes.avatarLoader} animation="wave" />
          </div>
        ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  avatarSkeletonContainer: {
    overflow: 'hidden',
    position: 'relative',
    minWidth: '150px',
    borderRadius: '20px',
    height: '100px',
    [theme.breakpoints.up('600')]: {
      minWidth: '250px',
      height: '115px',
    },
  },

  avatarLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100% !important',
    transform: 'scale(1) !important',
  },
}));
